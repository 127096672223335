// extracted by mini-css-extract-plugin
export var freeBox__dmPxM = "PlasmicHeader2-module--freeBox__dmPxM--JAjHV";
export var freeBox__lpjz = "PlasmicHeader2-module--freeBox__lpjz--I+Cj5";
export var iconLink = "PlasmicHeader2-module--iconLink--rQFeh";
export var link__ceaCr = "PlasmicHeader2-module--link__ceaCr--caHS3";
export var link__n0Brg = "PlasmicHeader2-module--link__n0Brg--DsthP";
export var link__wmDf5 = "PlasmicHeader2-module--link__wmDf5--TX+OZ";
export var link__xLs2C = "PlasmicHeader2-module--link__xLs2C--J8qt5";
export var menuButton = "PlasmicHeader2-module--menuButton--UC628";
export var root = "PlasmicHeader2-module--root--GlFf9";
export var svg___8ARuk = "PlasmicHeader2-module--svg___8ARuk--G+jF3";
export var svg__a9Cry = "PlasmicHeader2-module--svg__a9Cry--+W9ng";
export var text = "PlasmicHeader2-module--text--O+mHT";