// extracted by mini-css-extract-plugin
export var __wab_imgSpacer = "PlasmicFaq-module--__wab_img-spacer--XwtsU";
export var bigPlants = "PlasmicFaq-module--bigPlants--YbuQW";
export var blogPost___8CPij = "PlasmicFaq-module--blogPost___8CPij--NlzRf";
export var blogPost__kbF3G = "PlasmicFaq-module--blogPost__kbF3G--pKS-7";
export var blogPost__uhlTq = "PlasmicFaq-module--blogPost__uhlTq--eIOm2";
export var cactuses = "PlasmicFaq-module--cactuses--nV0O1";
export var column___26MGr = "PlasmicFaq-module--column___26MGr--s9m3J";
export var column___4Ntvg = "PlasmicFaq-module--column___4Ntvg--YnWw6";
export var column___5DQ1 = "PlasmicFaq-module--column___5DQ1--srZX5";
export var column__aVLwg = "PlasmicFaq-module--column__aVLwg--do7Gu";
export var column__alfn4 = "PlasmicFaq-module--column__alfn4--QU471";
export var column__anCfn = "PlasmicFaq-module--column__anCfn--zHJPi";
export var column__bqN1F = "PlasmicFaq-module--column__bqN1F--PI4I9";
export var column__d6KGy = "PlasmicFaq-module--column__d6KGy--+y5qB";
export var column__f8NfW = "PlasmicFaq-module--column__f8NfW--B8TVl";
export var column__fELfA = "PlasmicFaq-module--column__fELfA--88Mom";
export var column__iMa65 = "PlasmicFaq-module--column__iMa65--z-ZJw";
export var column__jnyPh = "PlasmicFaq-module--column__jnyPh--RCIvx";
export var column__kQez8 = "PlasmicFaq-module--column__kQez8--itun1";
export var column__lWEr = "PlasmicFaq-module--column__lWEr--AxqMk";
export var column__mFKj3 = "PlasmicFaq-module--column__mFKj3--jguly";
export var column__msE02 = "PlasmicFaq-module--column__msE02--dzpE2";
export var column__nt265 = "PlasmicFaq-module--column__nt265--mlUDP";
export var column__ofKqh = "PlasmicFaq-module--column__ofKqh--lRYwv";
export var column__qaN2W = "PlasmicFaq-module--column__qaN2W--7fGhc";
export var column__taG3X = "PlasmicFaq-module--column__taG3X--bXNeb";
export var column__u0UhT = "PlasmicFaq-module--column__u0UhT--HG9V5";
export var column__xfUzF = "PlasmicFaq-module--column__xfUzF--eSuiI";
export var columns___02YWk = "PlasmicFaq-module--columns___02YWk--6T00s";
export var columns__geFw = "PlasmicFaq-module--columns__geFw--zYXWX";
export var columns__sTXb = "PlasmicFaq-module--columns__sTXb--VRBnl";
export var columns__ssKo = "PlasmicFaq-module--columns__ssKo--Q3i1a";
export var columns__tz3Dj = "PlasmicFaq-module--columns__tz3Dj--8Z2ly";
export var columns__yhaS = "PlasmicFaq-module--columns__yhaS--JxXQl";
export var columns__zXNrF = "PlasmicFaq-module--columns__zXNrF--8jeJ3";
export var featureItem = "PlasmicFaq-module--featureItem--HviO1";
export var footer__dJaY = "PlasmicFaq-module--footer__dJaY--0NIbX";
export var footer__yn0Gw = "PlasmicFaq-module--footer__yn0Gw--asDas";
export var foreground2 = "PlasmicFaq-module--foreground2--I4gwO";
export var freeBox___16NuW = "PlasmicFaq-module--freeBox___16NuW--Tmebt";
export var freeBox___2A6Ga = "PlasmicFaq-module--freeBox___2A6Ga--+oZCz";
export var freeBox___2D61W = "PlasmicFaq-module--freeBox___2D61W--TCFgw";
export var freeBox___6QVqq = "PlasmicFaq-module--freeBox___6QVqq--s2IJE";
export var freeBox___87CEm = "PlasmicFaq-module--freeBox___87CEm--PyvSv";
export var freeBox__a6B5I = "PlasmicFaq-module--freeBox__a6B5I--gyJx6";
export var freeBox__aOz6V = "PlasmicFaq-module--freeBox__aOz6V--ZPBT8";
export var freeBox__bLkVo = "PlasmicFaq-module--freeBox__bLkVo--Tgh1I";
export var freeBox__bRnP = "PlasmicFaq-module--freeBox__bRnP--+FUUq";
export var freeBox__cB3Ui = "PlasmicFaq-module--freeBox__cB3Ui--ejeRa";
export var freeBox__cqnyD = "PlasmicFaq-module--freeBox__cqnyD--zUkeo";
export var freeBox__duhIg = "PlasmicFaq-module--freeBox__duhIg--KC4AY";
export var freeBox__eXfK5 = "PlasmicFaq-module--freeBox__eXfK5--KOb7+";
export var freeBox__ekZwg = "PlasmicFaq-module--freeBox__ekZwg--eoFql";
export var freeBox__f6Zpg = "PlasmicFaq-module--freeBox__f6Zpg--KOmAH";
export var freeBox__feOb = "PlasmicFaq-module--freeBox__feOb--Sf7of";
export var freeBox__g7Ab = "PlasmicFaq-module--freeBox__g7Ab--BzDfI";
export var freeBox__gBkox = "PlasmicFaq-module--freeBox__gBkox--4XtAe";
export var freeBox__gMhy5 = "PlasmicFaq-module--freeBox__gMhy5--YGSos";
export var freeBox__gYwpj = "PlasmicFaq-module--freeBox__gYwpj--bqrH0";
export var freeBox__gfXhZ = "PlasmicFaq-module--freeBox__gfXhZ--Mz2YB";
export var freeBox__gphPk = "PlasmicFaq-module--freeBox__gphPk--+Mv8m";
export var freeBox__hSRlT = "PlasmicFaq-module--freeBox__hSRlT--pamR9";
export var freeBox__hvR8I = "PlasmicFaq-module--freeBox__hvR8I--o-G4V";
export var freeBox__kJsN2 = "PlasmicFaq-module--freeBox__kJsN2--okaQT";
export var freeBox__kfDmF = "PlasmicFaq-module--freeBox__kfDmF--SRoDa";
export var freeBox__kwjcg = "PlasmicFaq-module--freeBox__kwjcg--s2Row";
export var freeBox__mXe3R = "PlasmicFaq-module--freeBox__mXe3R--dRYT-";
export var freeBox__mnGfk = "PlasmicFaq-module--freeBox__mnGfk--AJAP3";
export var freeBox__mySup = "PlasmicFaq-module--freeBox__mySup--W2xWM";
export var freeBox__nO3Mo = "PlasmicFaq-module--freeBox__nO3Mo--8GuUY";
export var freeBox__nOaA = "PlasmicFaq-module--freeBox__nOaA--rrrul";
export var freeBox__o7Z8S = "PlasmicFaq-module--freeBox__o7Z8S--C6FNp";
export var freeBox__omQr = "PlasmicFaq-module--freeBox__omQr--ZdNqk";
export var freeBox__ouFd = "PlasmicFaq-module--freeBox__ouFd--vEp2y";
export var freeBox__ox79N = "PlasmicFaq-module--freeBox__ox79N--03VZ6";
export var freeBox__pZhd = "PlasmicFaq-module--freeBox__pZhd--C8qct";
export var freeBox__pk8M = "PlasmicFaq-module--freeBox__pk8M--gRqAS";
export var freeBox__ppAhx = "PlasmicFaq-module--freeBox__ppAhx--mBie1";
export var freeBox__ptkn0 = "PlasmicFaq-module--freeBox__ptkn0--4GesG";
export var freeBox__qme = "PlasmicFaq-module--freeBox__qme--dmQrg";
export var freeBox__siPp = "PlasmicFaq-module--freeBox__siPp--JhI4Z";
export var freeBox__srx5E = "PlasmicFaq-module--freeBox__srx5E--Kq6E5";
export var freeBox__tWv06 = "PlasmicFaq-module--freeBox__tWv06--Ldazr";
export var freeBox__tqjqG = "PlasmicFaq-module--freeBox__tqjqG--ZSX8O";
export var freeBox__tt0Bs = "PlasmicFaq-module--freeBox__tt0Bs--6UgFQ";
export var freeBox__twLdR = "PlasmicFaq-module--freeBox__twLdR--ZYBa2";
export var freeBox__uI27O = "PlasmicFaq-module--freeBox__uI27O--13i6-";
export var freeBox__ueDev = "PlasmicFaq-module--freeBox__ueDev--YIS7T";
export var freeBox__vc2MQ = "PlasmicFaq-module--freeBox__vc2MQ--fHDKt";
export var freeBox__wQvRl = "PlasmicFaq-module--freeBox__wQvRl--lF7qk";
export var freeBox__wUsoo = "PlasmicFaq-module--freeBox__wUsoo--caVJG";
export var freeBox__wlrpz = "PlasmicFaq-module--freeBox__wlrpz--OQpc8";
export var freeBox__wsGrD = "PlasmicFaq-module--freeBox__wsGrD--nJZGU";
export var freeBox__yisDu = "PlasmicFaq-module--freeBox__yisDu--3R1ei";
export var freeBox__yoQft = "PlasmicFaq-module--freeBox__yoQft--j6l8o";
export var freeBox__zjVEq = "PlasmicFaq-module--freeBox__zjVEq--bn5YR";
export var h1___4OoqF = "PlasmicFaq-module--h1___4OoqF--H-1CU";
export var h1__oAuLw = "PlasmicFaq-module--h1__oAuLw--3SlA8";
export var h2__d755I = "PlasmicFaq-module--h2__d755I--GTmHZ";
export var h2__hSfbm = "PlasmicFaq-module--h2__hSfbm--wxx0o";
export var h2__uRKbH = "PlasmicFaq-module--h2__uRKbH--FXXRh";
export var h4__sXZt = "PlasmicFaq-module--h4__sXZt---JVv4";
export var h4__xvgAu = "PlasmicFaq-module--h4__xvgAu--cAsc+";
export var h4__y7O8M = "PlasmicFaq-module--h4__y7O8M--qA+Ia";
export var h4__ysI4N = "PlasmicFaq-module--h4__ysI4N--nMnxq";
export var h5___1Lvam = "PlasmicFaq-module--h5___1Lvam--wcnG0";
export var h5___1VJj = "PlasmicFaq-module--h5___1VJj--tP3b7";
export var h5__coXHy = "PlasmicFaq-module--h5__coXHy--9p5-Z";
export var h5__dTc7B = "PlasmicFaq-module--h5__dTc7B--xjwgL";
export var h5__guZg = "PlasmicFaq-module--h5__guZg--kAktN";
export var h5__qDfSc = "PlasmicFaq-module--h5__qDfSc--Q4nH+";
export var h5__ui8FA = "PlasmicFaq-module--h5__ui8FA--ZSSk7";
export var h5__wRxXi = "PlasmicFaq-module--h5__wRxXi--B+D4E";
export var header = "PlasmicFaq-module--header--+SShU";
export var header2__ftXK = "PlasmicFaq-module--header2__ftXK--cfF53";
export var header2__zwZgG = "PlasmicFaq-module--header2__zwZgG--h9hrn";
export var img___6LqEm = "PlasmicFaq-module--img___6LqEm--wKJUn";
export var img___6N8E8 = "PlasmicFaq-module--img___6N8E8--biobJ";
export var img__aFxMh = "PlasmicFaq-module--img__aFxMh--p7lTI";
export var img__bItH7 = "PlasmicFaq-module--img__bItH7--GKCjY";
export var img__bleNb = "PlasmicFaq-module--img__bleNb--9q93G";
export var img__cHDbQ = "PlasmicFaq-module--img__cHDbQ--97+0B";
export var img__d7Qwk = "PlasmicFaq-module--img__d7Qwk--NfU7Z";
export var img__dGXc = "PlasmicFaq-module--img__dGXc--eKE3P";
export var img__eoaCz = "PlasmicFaq-module--img__eoaCz--pBdup";
export var img__fBlTp = "PlasmicFaq-module--img__fBlTp--Th28l";
export var img__fKrkh = "PlasmicFaq-module--img__fKrkh--RcvNN";
export var img__hEch5 = "PlasmicFaq-module--img__hEch5--D6klk";
export var img__hkTzt = "PlasmicFaq-module--img__hkTzt--OBs4P";
export var img__j3PNy = "PlasmicFaq-module--img__j3PNy---+k7w";
export var img__j5N = "PlasmicFaq-module--img__j5N--a7LgW";
export var img__lC5R6 = "PlasmicFaq-module--img__lC5R6--lksxI";
export var img__lImPh = "PlasmicFaq-module--img__lImPh--tsEv6";
export var img__nBrL5 = "PlasmicFaq-module--img__nBrL5--xiVmV";
export var img__o5Lre = "PlasmicFaq-module--img__o5Lre--xhIbB";
export var img__oPk6N = "PlasmicFaq-module--img__oPk6N--ClhCf";
export var img__ol7AS = "PlasmicFaq-module--img__ol7AS--KDDiW";
export var img__rzXdb = "PlasmicFaq-module--img__rzXdb--SzWky";
export var img__us8N6 = "PlasmicFaq-module--img__us8N6--zibJF";
export var img__wKj5O = "PlasmicFaq-module--img__wKj5O--fqo82";
export var img__yHP3 = "PlasmicFaq-module--img__yHP3--FRiyu";
export var link___3S7M = "PlasmicFaq-module--link___3S7M--U4uAi";
export var link___7Hra = "PlasmicFaq-module--link___7Hra--O7nTF";
export var link___8OGxr = "PlasmicFaq-module--link___8OGxr--Pe5lQ";
export var link__a5FiN = "PlasmicFaq-module--link__a5FiN--In6jv";
export var link__c5U2X = "PlasmicFaq-module--link__c5U2X--NstZ6";
export var link__etUc7 = "PlasmicFaq-module--link__etUc7--LZ15f";
export var link__fRmE = "PlasmicFaq-module--link__fRmE--6Gq2c";
export var link__n6Abx = "PlasmicFaq-module--link__n6Abx--hmGiC";
export var link__o6I4 = "PlasmicFaq-module--link__o6I4--URn-4";
export var link__q8YZn = "PlasmicFaq-module--link__q8YZn--JBiWZ";
export var link__taW5E = "PlasmicFaq-module--link__taW5E--c0OOR";
export var link__tn69J = "PlasmicFaq-module--link__tn69J--P+HRI";
export var link__wFphm = "PlasmicFaq-module--link__wFphm---443y";
export var menuButton = "PlasmicFaq-module--menuButton--T-QV5";
export var productCard___37U = "PlasmicFaq-module--productCard___37U--izBaH";
export var productCard___6PBui = "PlasmicFaq-module--productCard___6PBui--TtG6k";
export var productCard__dTpSp = "PlasmicFaq-module--productCard__dTpSp--dwZiO";
export var productCard__fc5F = "PlasmicFaq-module--productCard__fc5F--LDt5a";
export var productCard__oi7EQ = "PlasmicFaq-module--productCard__oi7EQ--JvD8+";
export var productCard__t5RYx = "PlasmicFaq-module--productCard__t5RYx--BRr-L";
export var productCard__vpSrN = "PlasmicFaq-module--productCard__vpSrN--5DSXF";
export var productCard__wydKf = "PlasmicFaq-module--productCard__wydKf--pf3Z8";
export var ratings___1Voe5 = "PlasmicFaq-module--ratings___1Voe5--PmL3q";
export var ratings___3XUiy = "PlasmicFaq-module--ratings___3XUiy--LXtxs";
export var ratings___8AkMt = "PlasmicFaq-module--ratings___8AkMt--mAbJz";
export var ratings__a3UtR = "PlasmicFaq-module--ratings__a3UtR--MtWSZ";
export var ratings__k1FzS = "PlasmicFaq-module--ratings__k1FzS--m8NjU";
export var ratings__m5Nw1 = "PlasmicFaq-module--ratings__m5Nw1--3vSlh";
export var ratings__pCrG = "PlasmicFaq-module--ratings__pCrG--qSynv";
export var ratings__uyDZn = "PlasmicFaq-module--ratings__uyDZn--ty+mR";
export var root = "PlasmicFaq-module--root--Yl4sE";
export var svg___1LzJu = "PlasmicFaq-module--svg___1LzJu--ogLku";
export var svg___4YIo = "PlasmicFaq-module--svg___4YIo--iGJLt";
export var svg___6LXt7 = "PlasmicFaq-module--svg___6LXt7--BLYHk";
export var svg__aa2Yl = "PlasmicFaq-module--svg__aa2Yl--Ajapg";
export var svg__dz02D = "PlasmicFaq-module--svg__dz02D--7oQBl";
export var svg__fqQiQ = "PlasmicFaq-module--svg__fqQiQ--3B6Us";
export var svg__i5LV = "PlasmicFaq-module--svg__i5LV--Yn5Sn";
export var svg__jEf34 = "PlasmicFaq-module--svg__jEf34--AJiYl";
export var svg__krHcE = "PlasmicFaq-module--svg__krHcE--dFYCr";
export var svg__lGdT = "PlasmicFaq-module--svg__lGdT--p0O9N";
export var svg__llOpx = "PlasmicFaq-module--svg__llOpx--TScpx";
export var svg__ng6Ao = "PlasmicFaq-module--svg__ng6Ao--Yz5wg";
export var svg__nmSno = "PlasmicFaq-module--svg__nmSno--i7b65";
export var svg__or9Fc = "PlasmicFaq-module--svg__or9Fc--BXpa5";
export var svg__uZaGg = "PlasmicFaq-module--svg__uZaGg--pN3hb";
export var svg__w78AS = "PlasmicFaq-module--svg__w78AS--8TfWE";
export var text___05LMn = "PlasmicFaq-module--text___05LMn--sxnsy";
export var text___0QF = "PlasmicFaq-module--text___0QF--c47bs";
export var text___6RlWt = "PlasmicFaq-module--text___6RlWt--zxB1x";
export var text___6TtBy = "PlasmicFaq-module--text___6TtBy--6zznu";
export var text___6ZYar = "PlasmicFaq-module--text___6ZYar--bIb1d";
export var text___9M5F = "PlasmicFaq-module--text___9M5F--HlATZ";
export var text__aGnC = "PlasmicFaq-module--text__aGnC--F3na0";
export var text__b1I1S = "PlasmicFaq-module--text__b1I1S--5dk1e";
export var text__bU8B = "PlasmicFaq-module--text__bU8B--Jxlxb";
export var text__c0Dq1 = "PlasmicFaq-module--text__c0Dq1--ixuIx";
export var text__cbghM = "PlasmicFaq-module--text__cbghM--YX1S1";
export var text__dR2Az = "PlasmicFaq-module--text__dR2Az--LktOv";
export var text__dxWxz = "PlasmicFaq-module--text__dxWxz--jfxOk";
export var text__fFift = "PlasmicFaq-module--text__fFift--LX3E-";
export var text__fny1O = "PlasmicFaq-module--text__fny1O--eTI80";
export var text__h1VAt = "PlasmicFaq-module--text__h1VAt--qCYom";
export var text__hxu32 = "PlasmicFaq-module--text__hxu32--D2kxx";
export var text__ifKiT = "PlasmicFaq-module--text__ifKiT--hteZW";
export var text__jDAfm = "PlasmicFaq-module--text__jDAfm--Aq9Um";
export var text__jWzlM = "PlasmicFaq-module--text__jWzlM--rwj9d";
export var text__jXeN2 = "PlasmicFaq-module--text__jXeN2--GXoNd";
export var text__kb1Gi = "PlasmicFaq-module--text__kb1Gi--xRJFs";
export var text__lElj7 = "PlasmicFaq-module--text__lElj7--TOy2G";
export var text__mfX53 = "PlasmicFaq-module--text__mfX53--9F5jb";
export var text__ml4Js = "PlasmicFaq-module--text__ml4Js--vFUE+";
export var text__rAhSs = "PlasmicFaq-module--text__rAhSs--NcppX";
export var text__rg3U = "PlasmicFaq-module--text__rg3U--Xb7QY";
export var text__sMAsM = "PlasmicFaq-module--text__sMAsM--c-D2c";
export var text__sRIou = "PlasmicFaq-module--text__sRIou--d5Fr6";
export var text__szo8O = "PlasmicFaq-module--text__szo8O--lq-j1";
export var text__txUDe = "PlasmicFaq-module--text__txUDe--i8LfE";
export var text__u1YIg = "PlasmicFaq-module--text__u1YIg--ps8Jn";
export var text__veQyM = "PlasmicFaq-module--text__veQyM--CxONc";
export var text__w1Fyz = "PlasmicFaq-module--text__w1Fyz--eSCZB";
export var text__w2CaT = "PlasmicFaq-module--text__w2CaT--vH0wd";
export var text__wpndI = "PlasmicFaq-module--text__wpndI--SyG9d";
export var text__z2ItZ = "PlasmicFaq-module--text__z2ItZ--IK-q0";
export var text__z6Y = "PlasmicFaq-module--text__z6Y---Nzx1";